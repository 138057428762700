<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <b-tabs>
        <b-tab title="Pending">
      <div class="m-2">
      <b-tabs>
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button variant="primary" v-if="userRole=='finance'" @click="addNew()">
                <span class="text-nowrap">Add Stock Opname</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        </b-tabs>
      </div>
      <b-table
        ref="refStockOpnameListTable"
        class="position-relative"
        :items="fetchStockOpnames"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Number -->
        <template #cell(stock_opname_number)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.stock_opname_number
            }}</span>
          </div>
        </template>
        <!-- Column: Branch -->
        <template #cell(stock_opname_branch_id)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.branch.branch_name
            }}</span>
          </div>
        </template>
        <!-- Column: Date -->
        <template #cell(stock_opname_date)="data">
          {{ dateSimple(data.item.stock_opname_date, "YYYY-MM-DD HH:mm:ss") }}
        </template>
        <!-- Column: Hour -->
        <template #cell(stock_opname_hour)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.stock_opname_hour
            }}</span>
          </div>
        </template>
        <!-- Column: Description -->
        <template #cell(stock_opname_description)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.stock_opname_description
            }}</span>
          </div>
        </template>
        <template #cell(stock_opname_created_time)="data">
          {{
            dateSimple(
              data.item.stock_opname_created_time,
              "YYYY-MM-DD HH:mm:ss"
            )
          }}
        </template>
        <!-- Column: Action -->
        <template #cell(Action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="showDetail(data.item)">
              <feather-icon icon="ZoomInIcon" />
              <span class="align-middle ml-50">Detail</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
              userRole == 'laboratory-manager' &&
                  data.item.stock_opname_is_confirmed_by_lab_manager == NULL
              "
              @click="ConfirmStockOpnameByLabManager(data.item)"
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
              userRole == 'administrator' &&
                  data.item.stock_opname_is_confirmed_by_lab_manager == true &&
                  data.item.stock_opname_is_confirmed_by_administrator == NULL
              "
              @click="ConfirmStockOpnameByAdministrator(data.item)"
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
              userRole == 'finance' && data.item.stock_opname_is_confirmed_by_lab_manager == null"
              @click="editStockOpname(data.item)"
            >
              <feather-icon icon="Edit2Icon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalStockOpnames"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-tab>
<b-tab title="Needs Approval">
  <div class="m-2">
  <b-tabs>
    <!-- Table Top -->
    <b-row>
      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <label>Show</label>
        <v-select
          v-model="perPageNA"
          :options="perPageOptionsNA"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
        />
        <label>entries</label>
      </b-col>
      <b-col cols="12" md="6">
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchQueryNA"
            class="d-inline-block mr-1"
            placeholder="Search..."
          />
          <b-button variant="primary" v-if="userRole=='finance'" @click="addNew()">
            <span class="text-nowrap">Add Stock Opname</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    </b-tabs>
  </div>
  <b-table
    ref="refStockOpnameListTableNA"
    class="position-relative"
    :items="fetchStockOpnamesNA"
    responsive
    :fields="tableColumns"
    primary-key="id"
    :sort-by.sync="sortByNA"
    show-empty
    empty-text="No matching records found"
    :sort-desc.sync="isSortDirDescNA"
  >
    <!-- Column: Number -->
    <template #cell(stock_opname_number)="data">
      <div class="text-nowrap">
        <span class="align-text-top text-capitalize">{{
          data.item.stock_opname_number
        }}</span>
      </div>
    </template>
    <!-- Column: Branch -->
    <template #cell(stock_opname_branch_id)="data">
      <div class="text-nowrap">
        <span class="align-text-top text-capitalize">{{
          data.item.branch.branch_name
        }}</span>
      </div>
    </template>
    <!-- Column: Date -->
    <template #cell(stock_opname_date)="data">
      {{ dateSimple(data.item.stock_opname_date, "YYYY-MM-DD HH:mm:ss") }}
    </template>
    <!-- Column: Hour -->
    <template #cell(stock_opname_hour)="data">
      <div class="text-nowrap">
        <span class="align-text-top text-capitalize">{{
          data.item.stock_opname_hour
        }}</span>
      </div>
    </template>
    <!-- Column: Description -->
    <template #cell(stock_opname_description)="data">
      <div class="text-nowrap">
        <span class="align-text-top text-capitalize">{{
          data.item.stock_opname_description
        }}</span>
      </div>
    </template>
    <template #cell(stock_opname_created_time)="data">
      {{
        dateSimple(
          data.item.stock_opname_created_time,
          "YYYY-MM-DD HH:mm:ss"
        )
      }}
    </template>
    <!-- Column: Action -->
    <template #cell(Action)="data">
      <b-dropdown
        variant="link"
        no-caret
        :right="$store.state.appConfig.isRTL"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>

        <b-dropdown-item @click="showDetail(data.item)">
          <feather-icon icon="ZoomInIcon" />
          <span class="align-middle ml-50">Detail</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="
          userRole == 'laboratory-manager' &&
              data.item.stock_opname_is_confirmed_by_lab_manager == NULL
          "
          @click="ConfirmStockOpnameByLabManager(data.item)"
        >
          <feather-icon icon="CheckIcon" />
          <span class="align-middle ml-50">Confirm</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="
          userRole == 'administrator' &&
              data.item.stock_opname_is_confirmed_by_lab_manager == true &&
              data.item.stock_opname_is_confirmed_by_administrator == NULL
          "
          @click="ConfirmStockOpnameByAdministrator(data.item)"
        >
          <feather-icon icon="CheckIcon" />
          <span class="align-middle ml-50">Confirm</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="
          userRole == 'finance' && data.item.stock_opname_is_confirmed_by_lab_manager == null"
          @click="editStockOpname(data.item)"
        >
          <feather-icon icon="Edit2Icon" />
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
  <div class="mx-2 mb-2">
    <b-row>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted"
          >Showing {{ dataMetaNA.from }} to {{ dataMetaNAto }} of
          {{ dataMetaNA.of }} entries</span
        >
      </b-col>
      <!-- Pagination -->
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="currentPageNA"
          :total-rows="totalStockOpnamesNA"
          :per-page="perPageNA"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</b-tab>
<b-tab title="Finished">
  <div class="m-2">
  <b-tabs>
    <!-- Table Top -->
    <b-row>
      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <label>Show</label>
        <v-select
          v-model="perPageFinished"
          :options="perPageOptionsFinished"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
        />
        <label>entries</label>
      </b-col>
      <b-col cols="12" md="6">
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchQueryFinished"
            class="d-inline-block mr-1"
            placeholder="Search..."
          />
          <b-button variant="primary" v-if="userRole=='finance'" @click="addNew()">
            <span class="text-nowrap">Add Stock Opname</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    </b-tabs>
  </div>
  <b-table
    ref="refStockOpnameListTableFinished"
    class="position-relative"
    :items="fetchStockOpnamesFinished"
    responsive
    :fields="tableColumns"
    primary-key="id"
    :sort-by.sync="sortByFinished"
    show-empty
    empty-text="No matching records found"
    :sort-desc.sync="isSortDirDescFinished"
  >
    <!-- Column: Number -->
    <template #cell(stock_opname_number)="data">
      <div class="text-nowrap">
        <span class="align-text-top text-capitalize">{{
          data.item.stock_opname_number
        }}</span>
      </div>
    </template>
    <!-- Column: Branch -->
    <template #cell(stock_opname_branch_id)="data">
      <div class="text-nowrap">
        <span class="align-text-top text-capitalize">{{
          data.item.branch.branch_name
        }}</span>
      </div>
    </template>
    <!-- Column: Date -->
    <template #cell(stock_opname_date)="data">
      {{ dateSimple(data.item.stock_opname_date, "YYYY-MM-DD HH:mm:ss") }}
    </template>
    <!-- Column: Hour -->
    <template #cell(stock_opname_hour)="data">
      <div class="text-nowrap">
        <span class="align-text-top text-capitalize">{{
          data.item.stock_opname_hour
        }}</span>
      </div>
    </template>
    <!-- Column: Description -->
    <template #cell(stock_opname_description)="data">
      <div class="text-nowrap">
        <span class="align-text-top text-capitalize">{{
          data.item.stock_opname_description
        }}</span>
      </div>
    </template>
    <template #cell(stock_opname_created_time)="data">
      {{
        dateSimple(
          data.item.stock_opname_created_time,
          "YYYY-MM-DD HH:mm:ss"
        )
      }}
    </template>
    <!-- Column: Action -->
    <template #cell(Action)="data">
      <b-dropdown
        variant="link"
        no-caret
        :right="$store.state.appConfig.isRTL"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>

        <b-dropdown-item @click="showDetail(data.item)">
          <feather-icon icon="ZoomInIcon" />
          <span class="align-middle ml-50">Detail</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="
          userRole == 'laboratory-manager' &&
              data.item.stock_opname_is_confirmed_by_lab_manager == NULL
          "
          @click="ConfirmStockOpnameByLabManager(data.item)"
        >
          <feather-icon icon="CheckIcon" />
          <span class="align-middle ml-50">Confirm</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="
          userRole == 'administrator' &&
              data.item.stock_opname_is_confirmed_by_lab_manager == true &&
              data.item.stock_opname_is_confirmed_by_administrator == NULL
          "
          @click="ConfirmStockOpnameByAdministrator(data.item)"
        >
          <feather-icon icon="CheckIcon" />
          <span class="align-middle ml-50">Confirm</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="
          userRole == 'finance' && data.item.stock_opname_is_confirmed_by_lab_manager == null"
          @click="editStockOpname(data.item)"
        >
          <feather-icon icon="Edit2Icon" />
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
  <div class="mx-2 mb-2">
    <b-row>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted"
          >Showing {{ dataMetaFinished.from }} to {{ dataMetaFinishedto }} of
          {{ dataMetaFinished.of }} entries</span
        >
      </b-col>
      <!-- Pagination -->
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="currentPageFinished"
          :total-rows="totalStockOpnamesFinished"
          :per-page="perPageFinished"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</b-tab>
    </b-tabs>

    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  VBModal,
  BListGroup,
  BTabs,
  BTab,
  BListGroupItem,
} from "bootstrap-vue"
import axios from "axios"
import vSelect from "vue-select"
import store from "@/store"
import { getUserData } from "@/auth/utils"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { ref, onUnmounted } from "@vue/composition-api"
import { avatarText } from "@core/utils/filter"
import moment from "moment"
import { VueHorizontalTimeline } from "vue-horizontal-timeline"
import StockOpnameList from "./List"

const userRole = ""

const messageBox = ""
const to = ""
const cc = ""
const reason = ""
const StockOpnameId = ""
const messagesList = []
const statusItems = []
const items = []
const userId = getUserData().user_id
const modalDetailShow = false
const modalMessage = false
export default {
  components: {
  BTabs,
  BTab,
    VueHorizontalTimeline,
    BListGroup,
    BListGroupItem,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      items,
      statusItems,
      StockOpnameId,
      userId,
      messagesList,
    }
  },
  mounted() {
    this.userRole = this.$cookies.get("UserRole")
    this.$root.$on("refreshTable", text => {
      console.log(text) // here you need to use the arrow function
      this.$refs.refStockOpnameListTable.refresh()
      this.$refs.refStockOpnameListTableNA.refresh()
      this.$refs.refStockOpnameListTableFinished.refresh()
    })
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = "app-transfers"
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
      }
    })

    const isAddNewStockOpnameSidebarActive = ref(false)

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ]

    const {
      fetchStockOpnames,
      fetchStockOpnamesNA,
      fetchStockOpnamesFinished,
      tableColumns,
      perPage,
      currentPage,
      totalStockOpnames,
      dataMeta,
      dataMetaNA,
      dataMetaFinished,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStockOpnameListTable,
      refStockOpnameListTableNA,
      refStockOpnameListTableFinished,
      perPageNA,
      currentPageNA,
      totalStockOpnamesNA,
      totalStockOpnamesFinished,
      perPageFinished,
      currentPageFinished,
      refetchData,

      // UI
      resolveStockOpnameRoleVariant,
      resolveStockOpnameRoleIcon,
      resolveStockOpnameStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = StockOpnameList()

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ]

    return {
      to,
      cc,
      reason,
      messageBox,
      modalDetailShow,
      modalMessage,
      // Sidebar
      isAddNewStockOpnameSidebarActive,
      userRole,
      fetchStockOpnames,
      fetchStockOpnamesNA,
      fetchStockOpnamesFinished,
      tableColumns,
      perPage,
      currentPage,
      totalStockOpnames,
      dataMeta,
      dataMetaNA,
      dataMetaFinished,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStockOpnameListTable,
      refStockOpnameListTableNA,
      refStockOpnameListTableFinished,
      perPageNA,
      currentPageNA,
      totalStockOpnamesNA,
      totalStockOpnamesFinished,
      perPageFinished,
      currentPageFinished,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveStockOpnameRoleVariant,
      resolveStockOpnameRoleIcon,
      resolveStockOpnameStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
    }
  },
  methods: {
    deleteStockOpname(id) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(`${process.env.VUE_APP_API_DELETE_TRANSFER}${id}`, { headers })
        .then(response => {
          console.log(response)
          if (response.data.success === true) {
            this.$refs.refStockOpnameListTable.refresh()
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Congratulation",
                icon: "CoffeeIcon",
                variant: "success",
                text: "Your data has been deleted!",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Notification",
                icon: "BellIcon",
                variant: "danger",
                text: "Something went wrong",
              },
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    editStockOpname(data) {
      this.$cookies.set("StockOpnameDetail", data)
      this.$router.push({ name: "apps-stock-opname-edit" })
    },
    declineStockOpname(id) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const body = {
        time: moment(),
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/transactions/stock_opname/decline/${id}`,
          body,
          { headers },
        )
        .then(async response => {
          if (response.data.success === true) {
            this.$refs.refStockOpnameListTable.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Confirm Stock Opname Success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    ConfirmStockOpnameByFinance(data) {
      this.$cookies.set("StockOpnameDetail", data)
      this.$router.push({ name: "apps-stock-opname-confirm-finance" })
    },
    ConfirmStockOpnameByLabManager(data) {
      this.$cookies.set("StockOpnameDetail", data)
      this.$router.push({ name: "apps-stock-opname-confirm-labmanager" })
    },
    ConfirmStockOpnameByAdministrator(data) {
      this.$cookies.set("StockOpnameDetail", data)
      this.$router.push({ name: "apps-stock-opname-confirm-administrator" })
    },
    addNew() {
      this.$router.push({ name: "apps-stock-opname-create" })
    },
    dateSimple(value, format = "YYYY-MM-DD") {
      let dateRet = ""
      if (value !== null) {
        dateRet = moment(String(value)).format(format)
      } else {
        dateRet = null
      }
      return dateRet
    },
    showDetail(item) {
      this.$cookies.set("StockOpnameDetail", item)
      this.$router.push({ name: "apps-stock-opname-details" })
    },
    hasPermission(permission) {
      const permissions = this.$cookies.get("userPermissions").split(",")
      return permissions.includes(permission) ? true : false
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss"
</style>
