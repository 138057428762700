import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import Vue from 'vue'
import router from '../../../router'

export default function useMaterialRequestsList() {
  const toast = useToast()
  const refStockOpnameListTable = ref(null)
  const refStockOpnameListTableNA = ref(null)
  const refStockOpnameListTableFinished = ref(null)
  const tableColumns = [
    { key: 'stock_opname_number', sortable: true },
    { key: 'stock_opname_branch_id', sortable: true },
    { key: 'stock_opname_date', sortable: true },
    { key: 'stock_opname_hour', sortable: true },
    { key: 'stock_opname_description', sortable: true },
    { key: 'stock_opname_created_time', sortable: true },
    { key: 'Action', sortable: true },
  ]
  const perPage = ref(10)
  const totalStockOpname = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('stock_opname_number')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const perPageNA = ref(10)
  const totalStockOpnameNA = ref(0)
  const currentPageNA = ref(1)
  const perPageOptionsNA = [10, 25, 50, 100]
  const searchQueryNA = ref('')
  const sortByNA = ref('stock_opname_number')
  const isSortDirDescNA = ref(true)
  const statusFilterNA = ref(null)
  const perPageFinished = ref(10)
  const totalStockOpnameFinished = ref(0)
  const currentPageFinished = ref(1)
  const perPageOptionsFinished = [10, 25, 50, 100]
  const searchQueryFinished = ref('')
  const sortByFinished = ref('stock_opname_number')
  const isSortDirDescFinished = ref(true)
  const statusFilterFinished = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refStockOpnameListTable.value ? refStockOpnameListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalStockOpname.value,
    }
  })
  const dataMetaNA = computed(() => {
    const localItemsCount = refStockOpnameListTableNA.value ? refStockOpnameListTableNA.value.localItems.length : 0
    return {
      from: perPageNA.value * (currentPageNA.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageNA.value * (currentPageNA.value - 1) + localItemsCount,
      of: totalStockOpnameNA.value,
    }
  })

  const dataMetaFinished = computed(() => {
    const localItemsCount = refStockOpnameListTableFinished.value ? refStockOpnameListTableFinished.value.localItems.length : 0
    return {
      from: perPageFinished.value * (currentPageFinished.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageFinished.value * (currentPageFinished.value - 1) + localItemsCount,
      of: totalStockOpnameFinished.value,
    }
  })
  const refetchData = () => {
    refStockOpnameListTable.value.refresh()
    refStockOpnameListTableNA.value.refresh()
    refStockOpnameListTableFinished.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, sortBy, isSortDirDesc,currentPageFinished, perPageFinished, searchQueryFinished, statusFilterFinished, sortByFinished, isSortDirDescFinished,currentPageNA, perPageNA, searchQueryNA, statusFilterNA, sortByNA, isSortDirDescNA], () => {
    refetchData()
  })

  const fetchStockOpnames = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_STOCK_OPNAMES}`, {
        headers,
        params: {
          search: searchQuery.value,
          order_by: sortBy.value,
          order_sort: isSortDirDesc.value ? 'DESC' : 'ASC',
          is_finished: false,
        },
      })
      .then(response => {
        totalStockOpname.value = response.data.data.length
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  const fetchStockOpnamesNA = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_STOCK_OPNAMES}`, {
        headers,
        params: {
          search: searchQueryNA.value,
          order_by: sortByNA.value,
          order_sort: isSortDirDescNA.value ? 'DESC' : 'ASC',
          needs_approval: true,
        },
      })
      .then(response => {
        totalStockOpnameNA.value = response.data.data.length
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  const fetchStockOpnamesFinished = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_STOCK_OPNAMES}`, {
        headers,
        params: {
          search: searchQueryFinished.value,
          order_by: sortByFinished.value,
          order_sort: isSortDirDescFinished.value ? 'DESC' : 'ASC',
          is_finished: true,
        },
      })
      .then(response => {
        totalStockOpnameFinished.value = response.data.data.length
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchStockOpnames,
    tableColumns,
    perPage,
    currentPage,
    totalStockOpname,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refStockOpnameListTable,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    fetchStockOpnamesNA,
    perPageNA,
    currentPageNA,
    totalStockOpnameNA,
    dataMetaNA,
    perPageOptionsNA,
    searchQueryNA,
    sortByNA,
    isSortDirDescNA,
    refStockOpnameListTableNA,
    fetchStockOpnamesFinished,
    dataMetaFinished,
    perPageFinished,
    currentPageFinished,
    totalStockOpnameFinished,
    perPageOptionsFinished,
    searchQueryFinished,
    sortByFinished,
    isSortDirDescFinished,
    refStockOpnameListTableFinished,
    // Extra Filters
    statusFilter,
  }
}
